import { AppBadge, AppButton, AppButtons, AppCard, AppChip, AppCol, AppColor, AppContent, AppGrid, AppItem, AppItemDivider, AppLabel, AppLoadingCard, AppPage, AppRow, AppText, AppTitle } from "atomic";
import { BigNumber, ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useWallet } from "use-wallet";
import { WalletConnector } from "../components/WalletConnector";
import { EspersOracle__factory } from "../contracts";
import { ORACLE_OF_CHANGES_CONTRACT_ADDRESS } from "../contracts/addresses";
import { useActiveChain } from "../hooks/useActiveChainHook";
import { useReading } from "../hooks/useReadingHook";
import { hexagrams } from "../judgement";
import judgements from "../judgement/changes.json";
import { ERC761Attributes, trait } from "./CreateMetadataFolder";


const trigram_names: Record<string, string> = {
    "☰": "Heaven",
    "☱": "Lake",
    "☲": "Fire",
    "☳": "Thunder",
    "☴": "Wind",
    "☵": "Water",
    "☶": "Mountain",
    "☷": "Earth"
}
const trigram_colors: Record<string, AppColor> = {
    "☰": "dark",
    "☱": "secondary",
    "☲": "danger",
    "☳": "warning",
    "☴": "tertiary",
    "☵": "primary",
    "☶": "warning",
    "☷": "favorite"
}
export const binaryHegramsToTraditionalOrder = [63, 0, 17, 34, 23, 58, 2, 16, 55, 59, 7, 56, 61, 47, 4, 8, 25, 38, 3, 48, 41, 37, 32, 1, 57, 39, 33, 30, 18, 45, 28, 14, 60, 15, 40, 5, 53, 43, 20, 10, 35, 49, 31, 62, 24, 6, 26, 22, 29, 46, 9, 36, 52, 11, 13, 44, 54, 27, 50, 19, 51, 12, 21, 42]
const place = ["Above", "Below"];
export const findJudgement = (hexagramIndex: number) => {
    return judgements[binaryHegramsToTraditionalOrder.indexOf(hexagramIndex)]
}

export const getHexagramsFromBinary = (readingId: number) => {
    readingId -= 1;
    const first_hexagram = (readingId >> 6)
    const second_hexagram = readingId % 64

    return [first_hexagram, second_hexagram]
}

const Change: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const readingId = parseInt(id) || 0
    const setReading = useReading(x => x.setReading);
    const [metadata, setMetdata] = useState<{ attributes: ERC761Attributes, primary: string, secondary: string, name: string }>()
    const [hovering, setHovering] = useState<boolean>(false)
    const [forgetting, setForgetting] = useState<boolean>(false)
    const { isConnected, ethereum, chainId, account } = useWallet()
    const activeChainId = useActiveChain(x => x.chainId)
    const { push } = useHistory();
    useEffect(() => {
        if (readingId !== 0) {
            fetch("https://smol.mypinata.cloud/ipfs/Qmb6jq4xuK9qtFBNfiGd44cWkLa5KjhAKMps2mDmnrk5DS/" + readingId + ".json").then(async (meta) => {
                setMetdata(await meta.json());

            })
        }
    }, [readingId])

    if (id === "reading" || id === "consult") {
        return <></>
    }
    if (!metadata) {
        return <><AppLoadingCard /></>
    }
    const judgement = trait(metadata.attributes, "primary judgement")
    const hexagram = trait(metadata.attributes, "primary hexagram")
    const changingHexagram = trait(metadata.attributes, "secondary hexagram")
    const changingJudgement = trait(metadata.attributes, "secondary judgement")
    const aboveTrigram = trait(metadata.attributes, "primary trigram above")!
    const belowTrigram = trait(metadata.attributes, "primary trigram below")!
    const aboveChangingTrigram = trait(metadata.attributes, "secondary trigram above")!
    const belowChangingTrigram = trait(metadata.attributes, "secondary trigram below")!
    const changingName = trait(metadata.attributes, "secondary name")!
    const changingImage = trait(metadata.attributes, "secondary image")!
    const image = trait(metadata.attributes, "primary image")!
    const name = trait(metadata.attributes, "primary name")!
    return (
        <AppPage>
            <AppContent>
                <AppGrid>
                    <AppRow>
                        <AppCol sizeMd="6" sizeLg="8" sizeXs="24">
                            {<AppCard title={"Trigram Changes"} >
                                <div >
                                    <AppItem />
                                    <AppItem>
                                        <AppItem />
                                        <AppCol>
                                            <AppText color="medium">
                                                Above
                                            </AppText>

                                        </AppCol>
                                        <AppCol>
                                            <AppText color={trigram_colors[aboveTrigram]}>
                                                {aboveTrigram}
                                            </AppText>
                                        </AppCol>
                                        <AppCol>
                                            <AppText color="medium">
                                                🠒
                                            </AppText>
                                        </AppCol>
                                        <AppCol>
                                            <AppText color={trigram_colors[aboveChangingTrigram]}>
                                                {aboveChangingTrigram}
                                            </AppText>
                                        </AppCol>
                                    </AppItem>
                                    <AppItem />
                                </div>
                                <div >
                                    <AppItem />
                                    <AppItem>
                                        <AppItem />
                                        <AppCol>
                                            <AppText color="medium">
                                                Below
                                            </AppText>
                                        </AppCol>
                                        <AppCol>
                                            <AppText color={trigram_colors[belowTrigram]}>
                                                {belowTrigram}
                                            </AppText>
                                        </AppCol>
                                        <AppCol>
                                            <AppText color="medium">
                                                🠒

                                            </AppText>
                                        </AppCol>
                                        <AppCol>
                                            <AppText color={trigram_colors[belowChangingTrigram]}>
                                                {belowChangingTrigram}
                                            </AppText>
                                        </AppCol>
                                    </AppItem>
                                    <AppItem />
                                </div>
                            </AppCard>}

                        </AppCol>
                        <AppCol sizeMd="12" sizeLg={"8"} sizeXs="24">
                            <AppCard title={<AppItem>
                                <AppButtons>
                                    <AppTitle color={hovering ? "dark" : "tertiary"} >
                                        {hexagram}
                                    </AppTitle>
                                </AppButtons>
                                <AppTitle>
                                    Change #{readingId}
                                </AppTitle>
                                <AppButtons slot="end">
                                    <AppTitle color={!hovering ? "dark" : "tertiary"} >
                                        {changingHexagram}
                                    </AppTitle>
                                </AppButtons>

                            </AppItem>
                            }>
                                <img onMouseOver={(e) => { setHovering(true) }} onMouseOut={() => {
                                    setHovering(false);
                                }} src={hovering ? metadata.secondary : metadata.primary} alt="" />
                                <AppItem>
                                    {!hovering && <AppButtons slot="start">
                                        <AppChip onClick={() => setHovering(false)} color={hovering ? "clear" : "medium"} >
                                            {name}</AppChip>
                                    </AppButtons>}
                                    {hovering && <AppButtons slot="end">
                                        <AppChip onClick={() => {
                                            setHovering(true);
                                        }} color={!hovering ? "clear" : "medium"} >
                                            {changingName}</AppChip>
                                    </AppButtons>}

                                </AppItem>
                            </AppCard>
                        </AppCol>
                        <AppCol sizeMd="6" sizeLg="8" sizeXs="24">
                            {<AppCard title={hovering ? changingName : name}>
                                <AppItem />
                                <AppTitle>
                                    Judgement
                                </AppTitle>
                                <AppText color="medium">
                                    {!hovering ? judgement : changingJudgement}
                                </AppText>
                                <AppItemDivider />
                                <AppTitle >
                                    Image
                                </AppTitle>
                                <AppText color="medium">
                                    {!hovering ? image : changingImage}
                                </AppText>
                            </AppCard>}
                        </AppCol>
                    </AppRow>
                </AppGrid>
                {forgetting && <AppLoadingCard title="Forgetting this reading..." />}
                {<AppButton disabled={forgetting} fill="outline" expand="full" onClick={() => {
                    push("/")
                }}>
                    Consult Oracle
                </AppButton>}
            </AppContent>

        </AppPage >

    );
};

export default Change;
