import { AppBadge, AppButton, AppButtons, AppCard, AppChip, AppCol, AppColor, AppContent, AppGrid, AppItem, AppItemDivider, AppLabel, AppLoadingCard, AppModal, AppPage, AppRow, AppText, AppTitle, usePageTitle } from "atomic";
import { BigNumber, ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useWallet } from "use-wallet";
import { WalletConnector } from "../components/WalletConnector";
import { EspersOracle__factory } from "../contracts";
import { ORACLE_OF_CHANGES_CONTRACT_ADDRESS } from "../contracts/addresses";
import { useActiveChain } from "../hooks/useActiveChainHook";
import { useReading } from "../hooks/useReadingHook";
import { hexagrams } from "../judgement";
import judgements from "../judgement/changes.json";
import { ERC761Attributes, trait } from "./CreateMetadataFolder";


const trigram_names: Record<string, string> = {
    "☰": "Heaven",
    "☱": "Lake",
    "☲": "Fire",
    "☳": "Thunder",
    "☴": "Wind",
    "☵": "Water",
    "☶": "Mountain",
    "☷": "Earth"
}
const trigram_colors: Record<string, AppColor> = {
    "☰": "dark",
    "☱": "secondary",
    "☲": "danger",
    "☳": "warning",
    "☴": "tertiary",
    "☵": "primary",
    "☶": "warning",
    "☷": "favorite"
}
export const binaryHegramsToTraditionalOrder = [63, 0, 17, 34, 23, 58, 2, 16, 55, 59, 7, 56, 61, 47, 4, 8, 25, 38, 3, 48, 41, 37, 32, 1, 57, 39, 33, 30, 18, 45, 28, 14, 60, 15, 40, 5, 53, 43, 20, 10, 35, 49, 31, 62, 24, 6, 26, 22, 29, 46, 9, 36, 52, 11, 13, 44, 54, 27, 50, 19, 51, 12, 21, 42]
const place = ["Above", "Below"];
export const findJudgement = (hexagramIndex: number) => {
    return judgements[binaryHegramsToTraditionalOrder.indexOf(hexagramIndex)]
}

export const getHexagramsFromBinary = (readingId: number) => {
    readingId -= 1;
    const first_hexagram = (readingId >> 6)
    const second_hexagram = readingId % 64

    return [first_hexagram, second_hexagram]
}

const Reading: React.FC = () => {
    const readingId = useReading(x => x.reading);
    const setReading = useReading(x => x.setReading);
    const [metadata, setMetdata] = useState<{ attributes: ERC761Attributes, primary: string, secondary: string, name: string }>()
    const [hovering, setHovering] = useState<boolean>(false)
    const [forgetting, setForgetting] = useState<boolean>(false)
    const [interval, setDetectionInterval] = useState<any>()
    const { isConnected, ethereum, chainId, account } = useWallet()
    const activeChainId = useActiveChain(x => x.chainId)
    const { push } = useHistory();
    useEffect(() => {
        if (isConnected() && activeChainId === chainId) {
            const signer = (new ethers.providers.Web3Provider(ethereum)).getSigner();
            const oracleOfChangesAddress = ORACLE_OF_CHANGES_CONTRACT_ADDRESS[String(chainId)];
            const ORACLEOFCHANGES = EspersOracle__factory.connect(oracleOfChangesAddress, signer)
            const detectReading = setInterval(() => {
                ORACLEOFCHANGES.callStatic.lastReading().then((response) => {
                    const hexReading = response.toHexString();
                    const number = parseInt(hexReading, 16)
                    setReading(number);
                    if (number !== 0 && number !== 8888888888888888) {
                        clearInterval(detectReading);
                        ORACLEOFCHANGES.callStatic.uri(hexReading).then((metadata_uri) => {
                            fetch(metadata_uri).then(async (meta) => {
                                setMetdata(await meta.json());
                            })
                        })
                    }

                    if (number === 0) {
                        push("/consult");
                        clearInterval(detectReading);
                        setReading(undefined);
                    }
                })
            }, 2000)
            setDetectionInterval(detectReading);
        }
        if (!readingId) {

            return;
        }
    }
        , [account, activeChainId, chainId, ethereum, isConnected, push, readingId, setReading]);
    const setTitle = usePageTitle(x => x.setTitle)
    useEffect(() => {
        setTitle(metadata?.name);
    }, [metadata?.name, setTitle])
    if (!isConnected()) {
        return <>
            <WalletConnector />
        </>
    }
    if (readingId === 8888888888888888) {
        return <>
            <AppLoadingCard title="Reading In Progress" />
        </>
    }

    if (typeof readingId !== "number") {
        return <>
            <AppLoadingCard />
            <AppButton color="clear" expand="full" routerLink={"/"}>
                Go Back
            </AppButton>
        </>
    }

    if (!metadata) {
        return <><AppLoadingCard /></>
    }
    const judgement = trait(metadata.attributes, "primary judgement")
    const hexagram = trait(metadata.attributes, "primary hexagram")
    const changingHexagram = trait(metadata.attributes, "secondary hexagram")
    const changingJudgement = trait(metadata.attributes, "secondary judgement")
    const aboveTrigram = trait(metadata.attributes, "primary trigram above")!
    const belowTrigram = trait(metadata.attributes, "primary trigram below")!
    const aboveChangingTrigram = trait(metadata.attributes, "secondary trigram above")!
    const belowChangingTrigram = trait(metadata.attributes, "secondary trigram below")!
    const changingName = trait(metadata.attributes, "secondary name")!
    const changingImage = trait(metadata.attributes, "secondary image")!
    const image = trait(metadata.attributes, "primary image")!
    const name = trait(metadata.attributes, "primary name")!

    return (
        <AppPage>
            <AppContent>
                <AppGrid>
                    <AppRow>
                        {forgetting && <AppModal isOpen={true} onDismiss={function (): void {
                        }}><AppLoadingCard title="Lighting some sage 🌿🔥..." /></AppModal>}
                        <AppCol sizeMd="6" sizeLg="7" sizeXs="24">
                            {<AppCard title={"Trigram Changes"} >
                                <div >
                                    <AppItem />
                                    <AppItem>
                                        <AppItem />
                                        <AppCol>
                                            <AppTitle color={trigram_colors[aboveTrigram]}>
                                                {aboveTrigram}
                                            </AppTitle>
                                        </AppCol>
                                        <AppCol>
                                            <AppText color="medium">
                                                🠒
                                            </AppText>
                                        </AppCol>
                                        <AppCol>
                                            <AppTitle color={trigram_colors[aboveChangingTrigram]}>
                                                {aboveChangingTrigram}
                                            </AppTitle>
                                        </AppCol>
                                    </AppItem>
                                    <AppItem />
                                </div>
                                <div >
                                    <AppItem />
                                    <AppItem>
                                        <AppItem />
                                        <AppCol>
                                            <AppTitle color={trigram_colors[belowTrigram]}>
                                                {belowTrigram}
                                            </AppTitle>
                                        </AppCol>
                                        <AppCol>
                                            <AppText color="medium">
                                                🠒

                                            </AppText>
                                        </AppCol>
                                        <AppCol>
                                            <AppTitle color={trigram_colors[belowChangingTrigram]}>
                                                {belowChangingTrigram}
                                            </AppTitle>
                                        </AppCol>
                                    </AppItem>
                                    <AppItem />
                                </div>
                            </AppCard>}

                        </AppCol>
                        <AppCol sizeMd="12" sizeLg={"10"} sizeXs="24">
                            <AppCard title={<AppItem>
                                <AppButtons>
                                    <AppButton fill="solid" color="clear" onClick={() => setHovering(false)}  >
                                        <AppTitle color={hovering ? "dark" : "medium"} >
                                            {hexagram}
                                        </AppTitle>
                                        {name.split("-")[0].replace(/[{()}]/g, '')}
                                    </AppButton>
                                </AppButtons>
                                <AppButtons slot="end">
                                    <AppButton fill="solid" color="light" onClick={() => setHovering(true)}  >
                                        <AppTitle color={!hovering ? "dark" : "medium"} >
                                            {changingHexagram}
                                        </AppTitle>
                                        {changingName.split("-")[0].replace(/[{()}]/g, '')}
                                    </AppButton>
                                </AppButtons>

                            </AppItem>
                            }>
                                {/* {<img onMouseOver={(e) => { setHovering(true) }} onMouseOut={() => {
                                setHovering(false);
                            }} className={!hovering ? "fadeIn" : "fadeOut"} src={metadata.primary} alt="" />} */}
                                {<img onMouseOver={(e) => { setHovering(true) }} onMouseOut={() => {
                                    setHovering(false);
                                }} className={"fadeIn"} src={hovering ? metadata.secondary.replace("https://ipfs.io/", "https://smol.mypinata.cloud/") : metadata.primary.replace("https://ipfs.io/", "https://smol.mypinata.cloud/")} alt="" />}
                                <AppItem>
                                    <AppButtons slot="start">
                                        <AppButton color={hovering ? "light" : "tertiary"} fill={hovering ? "solid" : "outline"} onClick={() => setHovering(false)}  >
                                            <AppText color={!hovering ? "medium" : "dark"}>

                                                {name.split("-")[1]}
                                            </AppText>
                                        </AppButton>
                                    </AppButtons>
                                    <AppButtons slot="end">
                                        <AppButton color={!hovering ? "light" : "tertiary"} fill={!hovering ? "solid" : "outline"} onClick={() => {
                                            setHovering(true);
                                        }}  >
                                            <AppText color={"medium"}>
                                                {changingName.split("-")[1]}
                                            </AppText></AppButton>
                                    </AppButtons>
                                </AppItem>
                            </AppCard>
                        </AppCol>
                        <AppCol sizeMd="6" sizeLg="7" sizeXs="24">
                            {<AppCard title={hovering ? changingName : name}>
                                <AppItem />
                                <AppTitle>
                                    Judgement
                                </AppTitle>
                                <AppText color="medium">
                                    {!hovering ? judgement : changingJudgement}
                                </AppText>
                                <AppItemDivider />
                                <AppTitle >
                                    Image
                                </AppTitle>
                                <AppText color="medium">
                                    {!hovering ? image : changingImage}
                                </AppText>
                            </AppCard>}
                        </AppCol>
                    </AppRow>
                </AppGrid>
                {<AppButton color="light" disabled={forgetting} fill="solid" expand="full" onClick={() => {
                    const signer = (new ethers.providers.Web3Provider(ethereum)).getSigner();
                    const oracleOfChangesAddress = ORACLE_OF_CHANGES_CONTRACT_ADDRESS[String(chainId)];
                    const ORACLEOFCHANGES = EspersOracle__factory.connect(oracleOfChangesAddress, signer)
                    account && (ORACLEOFCHANGES.functions as any).forgetReading().then((response: any) => {
                        setForgetting(true);
                        const detectForgotten = setInterval(() => {
                            ORACLEOFCHANGES.callStatic.lastReading().then((response) => {
                                const hexReading = response.toHexString();
                                const number = parseInt(hexReading, 16)
                                if (number === 0) {
                                    push("/consult");
                                    setForgetting(false);
                                    clearInterval(detectForgotten);
                                    setReading(undefined);

                                }
                            })
                        }, 2000)
                    });
                }}>
                    Consult Oracle Again
                </AppButton>}

            </AppContent>
        </AppPage >
    );
};

export default Reading;
