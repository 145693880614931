import { AppCard, AppChip, AppCol, AppContent, AppImg, AppItem, AppRow } from "atomic";
import saveAs from "file-saver";
import JSZip from 'jszip';
import { useEffect, useState } from "react";
import { binaryHexagrams } from "../judgement";
import { binaryHegramsToTraditionalOrder, findJudgement, getHexagramsFromBinary } from "./Reading";

export const trait = (attributes: ERC761Attributes, trait_type: string) => {
    return attributes.find(x => x.trait_type === trait_type)?.trait_value
}

export type ERC761Attributes = { trait_type: string; trait_value: string; }[];

export const EspersOracleReadingCard: React.FC<{ image: string, attributes: ERC761Attributes, primary: string, secondary: string }> = ({ image, secondary, attributes }) => {
    return <AppCard subTitle={"Oracle Of Changes#" + trait(attributes, "id") + " "} title={trait(attributes, "primary name")?.split("0")[0] + " => " + trait(attributes, "secondary name")}>
        <AppRow>
            <AppCol>
                {attributes.filter(x => x.trait_type.includes("primary") && !x.trait_type.includes("judgement")).map(x => <AppItem>
                    {x.trait_type}<AppChip>{x.trait_value}</AppChip>
                </AppItem>)}
            </AppCol>

            <AppCol>
                <AppImg alt="" src={image} />
            </AppCol>
            <AppCol>
                {attributes.filter(x => x.trait_type.includes("secondary") && !x.trait_type.includes("judgement")).map(x => <AppItem>
                    {x.trait_type}<AppChip>{x.trait_value}</AppChip>
                </AppItem>)}

            </AppCol>
        </AppRow>
    </AppCard>
}

export const CreateMetaDataFolder: React.FC = () => {
    const [metadata, setMeta] = useState({ attributes: [] })
    useEffect(() => {
        const ids = Array.from(Array(4096).keys())
        var zip = new JSZip();
        var metadatas = zip.folder("metadata");

        ids.forEach((id) => {
            id += 1;
            const [first_hexagram, second_hexagram] = getHexagramsFromBinary(id)
            const secondaryJudgement = findJudgement(second_hexagram)
            const primaryJudgement = findJudgement(first_hexagram)
            const metadata = {
                "name": binaryHexagrams[first_hexagram] + binaryHexagrams[second_hexagram],
                "description": primaryJudgement.image,
                "image": "https://ipfs.io/ipfs/QmeVFvoULZgjTnxA5J5keBd4PQ6JWMj25tUPuqDnaHCAm7/" + (binaryHegramsToTraditionalOrder.indexOf(first_hexagram) + 1)  + ".png",
                "external_uri": "https://changes.espersoracle.com/#" + id,
                "primary": "https://ipfs.io/ipfs/QmeVFvoULZgjTnxA5J5keBd4PQ6JWMj25tUPuqDnaHCAm7/" + (binaryHegramsToTraditionalOrder.indexOf(first_hexagram) + 1) + ".png",
                "secondary": "https://ipfs.io/ipfs/QmeVFvoULZgjTnxA5J5keBd4PQ6JWMj25tUPuqDnaHCAm7/" + (binaryHegramsToTraditionalOrder.indexOf(second_hexagram) + 1) + ".png",
                attributes: [
                    {
                        trait_type: "id",
                        trait_value: id
                    },

                    {
                        trait_type: "primary name",
                        trait_value: primaryJudgement.name
                    },
                    {
                        trait_type: "secondary name",
                        trait_value: secondaryJudgement.name
                    },
                    {
                        trait_type: "primary hexagram",
                        trait_value: binaryHexagrams[first_hexagram]
                    },
                    {
                        trait_type: "secondary hexagram",
                        trait_value: binaryHexagrams[second_hexagram]
                    },
                    {
                        trait_type: "primary trigram above",
                        trait_value: primaryJudgement.hexagram[0]
                    },
                    {
                        trait_type: "primary trigram below",
                        trait_value: primaryJudgement.hexagram[1]
                    },
                    {
                        trait_type: "secondary trigram above",
                        trait_value: secondaryJudgement.hexagram[0]
                    },
                    {
                        trait_type: "secondary trigram below",
                        trait_value: secondaryJudgement.hexagram[1]
                    },
                    {
                        trait_type: "primary judgement",
                        trait_value: primaryJudgement.judgement
                    },
                    {
                        trait_type: "secondary judgement",
                        trait_value: secondaryJudgement.judgement
                    },
                    {
                        trait_type: "primary image",
                        trait_value: primaryJudgement.image
                    },
                    {
                        trait_type: "secondary image",
                        trait_value: secondaryJudgement.image
                    },

                ]



            }

            // Generate a directory within the Zip file structure

            // Add a file to the directory, in this case an image with data URI as contents
            metadatas!.file(id + ".json", JSON.stringify(metadata));
            console.log(metadatas, metadata);
            setMeta(metadata as any);
            // Generate the zip file asynchronously
        });
        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                // Force down of the Zip file
                saveAs(content, "archive.zip");
            });


    }, [])

    return <AppContent>
        <EspersOracleReadingCard {...metadata as any} />
    </AppContent>
}