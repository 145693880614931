import { AppAvatar, AppGrid, AppImg, AppItem, AppList, AppText, AppTitle } from "atomic"
import { chainIdInfo } from "./WalletConnector"

export const AboutWidget: React.ReactFragment =
    <AppGrid>
        <AppList>
            <AppItem>
                <AppText>
                    Oracle of changes cannot be held responsible for changes to your fate.
                </AppText>
            </AppItem>
            <AppItem>
                <AppTitle>
                    Contract Addresses:
                </AppTitle>
            </AppItem>
            <AppItem color="paper" onClick={() => { }} href="https://bscscan.com/address/0x0d0546694c0b406d9cdf646cec7728b859967652">
                <AppImg src={chainIdInfo["56"].icon} alt={""} />
                BSC:<AppText color="primary">
                    0x0d0546694c0b406d9cdf646cec7728b859967652
                </AppText>
            </AppItem>
            <AppItem color="paper" onClick={() => { }} href="https://polygonscan.com/address/0x7f6dfa70b65e0bfa3040270daa493fbf288f3536">
                <AppImg src={chainIdInfo["137"].icon} alt={""} />
                MATIC:<AppText color="primary">
                    0x7f6dfa70b65e0bfa3040270daa493fbf288f3536
                </AppText>
            </AppItem>
        </AppList>
    </AppGrid>
