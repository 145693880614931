import { AppAvatar, AppButton, AppButtons, AppChip, AppGrid, AppImg, AppItem, AppItemDivider, AppLabel, AppList, AppModal, AppSpinner, AppTitle } from "atomic";
import { utils } from "ethers";
import React, { useState } from "react";
import { useWallet } from "use-wallet";
import { useActiveChain } from "../hooks/useActiveChainHook";



export const chainIdInfo: Record<string, { name: string, currency: string, icon: string }> =
{
    // "42": {
    //     name: "Ethereum Testnet (KOVAN)",
    //     currency: "ETH",
    //     icon: "https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/32/color/eth.png"
    // },
    "137": {
        name: "Polygon",
        currency: "MATIC",
        icon: "https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/32/color/matic.png"
    },
    // "43114": {
    //     "name": "Avalanche",
    //     "currency": "AVAX",
    //     icon: "https://smol.mypinata.cloud/ipfs/QmaUSSXJJdRLFw5VZBqFEwKo5K3je6Ayo5PLEwsA8KrwX2"
    // },
    // "42220": {
    //     "name": "Celo Mainnet",
    //     "currency": "CELO",
    //     icon: "https://smol.mypinata.cloud/ipfs/QmYcpQeqoGiZfJ4zAUdrJc7c6vwyFDnE6oVJHMgzDzXzyK"
    // }
//}
"56": {
    name: "Binance Smart Chain",
    currency: "BSC",
    icon: "https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/32/color/bnb.png"
}
}

export const ChainIdIcon: React.FC<{ chainId?: string, onClick?: () => void }> = ({ chainId, onClick }) => {
    const activeChainId = useActiveChain(x => x.chainId);
    if (!chainId) {
        return <></>
    }
    const info = chainIdInfo[chainId];
    return info ? <AppChip color={String(activeChainId) === chainId ? "favorite" : "medium"} onClick={onClick}><AppGrid><AppImg alt={chainId} src={chainIdInfo[chainId].icon}></AppImg></AppGrid>{chainIdInfo[chainId].name} </AppChip> : <>{chainId}</>
}

export const WalletConnector: React.FC = () => {
    const { status, chainId, isConnected } = useWallet()
    const activeChainId = useActiveChain(x => x.chainId);
    const [showWallet, setShowWallet] = useState<boolean>(false)
    return <>
        < AppModal isOpen={showWallet} onDismiss={() => {
            setShowWallet(false);
        }} title={isConnected() ? chainIdInfo[String(chainId)]?.name + " Wallet" : "Wallet"}>
            <EVMWallet />
            {isConnected() && activeChainId === chainId ? <AppButton expand="full" color="light" onClick={() => { setShowWallet(false) }}>
                OK
            </AppButton> : <></>}
        </AppModal >
        {status === "disconnected" ? <AppButton expand="full" onClick={() => setShowWallet(true)}>Connect Wallet</AppButton> : <AppButton onClick={() => {
            setShowWallet(true);
        }}>{status} to <ChainIdIcon chainId={String(chainId)} /></AppButton>}
    </>
}


export const EVMWallet: React.FC = () => {
    const { connect, status, error, balance, account, chainId, isConnected } = useWallet()
    const activeChainId = useActiveChain(x => x.chainId)
    const { setChainId } = useActiveChain();
    const chainInfo = chainIdInfo[String(activeChainId)] || { currency: "unknown", icon: "", name: "unkown" }
    const isCorrectChain = chainId === activeChainId;

    const switchChains = async () => {
        try {
            await (window as any).ethereum.sendAsync({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: utils.hexlify(activeChainId) }],
            });
        } catch (switchError: any) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await (window as any).ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{ chainId: utils.hexlify(activeChainId) }],
                    });
                } catch (addError) {
                    // handle "add" error
                }
            }
            // handle other "switch" errors
        }

    }


    const prettyBalance = chainInfo.currency + " " + String(parseInt(balance) / (1000000000000000000))
    if (!isCorrectChain) {
        return <>
            {isConnected() ? <>
                {account && < AppItem>
                    <AppLabel>Address: </AppLabel>
                    <AppButtons slot='end'>
                        {account}
                    </AppButtons>
                </AppItem>}
                <AppItemDivider />
                <AppItem><AppLabel>Status:</AppLabel>
                    <AppButtons slot="end"><AppChip color="danger">connected to a different chain</AppChip>
                    </AppButtons>
                    {status}
                </AppItem>
                <AppButton color="light" expand="full" fill="solid" onClick={switchChains}> Switch to {chainInfo.name} to continue </AppButton>
            </> : <AppGrid>
                <AppItem>
                    Supported Chains: {Object.keys(chainIdInfo).map((id) => <ChainIdIcon key={id} onClick={() => {
                        setChainId(parseInt(id, 10))
                    }} chainId={id} />)}
                </AppItem>
                <AppItemDivider color="clear" />

                <AppButton fill="outline" expand="full" onClick={() => {
                    if (activeChainId !== chainId) {
                        switchChains().then(() => {
                            connect("injected")
                        })
                    }
                }} >
                    Connect with Metamask
                    <AppAvatar >

                        <AppImg alt="metamask" src="https://github.com/MetaMask/brand-resources/raw/master/SVG/metamask-fox.svg" />
                    </AppAvatar>
                </AppButton>
            </AppGrid>
            }
        </ >
    }
    return <>
        <AppList>
            {account && < AppItem >
                <AppLabel>Address:</AppLabel>
                <AppButtons slot='end'>{account}</AppButtons>
            </AppItem>}
            <AppItemDivider />
            <AppItem><AppLabel>Status:</AppLabel>
                <AppChip color={status === "error" ? "danger" : status === "connected" ? "primary" : "medium"}>
                    {status}
                </AppChip>
            </AppItem>
            {error && error?.message}

            {status === 'connected' && (<>
                <AppItem>
                    <AppLabel>Balance:</AppLabel>
                    <AppButtons slot="end">
                        {balance ? <AppTitle color={balance === "0" ? "danger" : "favorite"}>{prettyBalance}
                        </AppTitle> : <AppSpinner />}
                    </AppButtons>
                </AppItem>
            </>
            )}
        </AppList>
    </ >

}