export const ORACLE_OF_CHANGES_CONTRACT_ADDRESS: Record<string, string> = {
    "42": "0x0818622bc3497ef87eecc6e6443b9183481b323e",
    "137": "0x7f6dfa70B65e0BFA3040270DaA493fBf288f3536",
    "56":"0x0D0546694c0B406d9Cdf646CEC7728B859967652"
}
export const LINK_CONTRACT_ADDRESS: Record<string, string> = {
    "1": "0x514910771af9ca656af840dff83e8264ecf986ca",
    "42": "0xa36085F69e2889c224210F603D836748e7dC0088",
    "137": "0xb0897686c545045aFc77CF20eC7A532E3120E0F1",
    "43114": "0x0b9d5D9136855f6FEc3c0993feE6E9CE8a297846",
    "56":"0x404460C6A5EdE2D891e8297795264fDe62ADBB75"

}