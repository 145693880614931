export const hexagrams = [
    '䷀',
    '䷁',
    '䷂',
    '䷃',
    '䷄',
    '䷅',
    '䷆',
    '䷇',
    '䷈',
    '䷉',
    '䷊',
    '䷋',
    '䷌',
    '䷍',
    '䷎',
    '䷏',
    '䷐',
    '䷑',
    '䷒',
    '䷓',
    '䷔',
    '䷕',
    '䷖',
    '䷗',
    '䷘',
    '䷙',
    '䷚',
    '䷛',
    '䷜',
    '䷝',
    '䷞',
    '䷟',
    '䷠',
    '䷡',
    '䷢',
    '䷣',
    '䷤',
    '䷥',
    '䷦',
    '䷧',
    '䷨',
    '䷩',
    '䷪',
    '䷫',
    '䷬',
    '䷭',
    '䷮',
    '䷯',
    '䷰',
    '䷱',
    '䷲',
    '䷳',
    '䷴',
    '䷵',
    '䷶',
    '䷷',
    '䷸',
    '䷹',
    '䷺',
    '䷻',
    '䷼',
    '䷽',
    '䷾',
    '䷿']
export const binaryHexagrams = "䷁䷗䷆䷒䷎䷣䷭䷊䷏䷲䷧䷵䷽䷶䷟䷡䷇䷂䷜䷻䷦䷾䷯䷄䷬䷐䷮䷹䷞䷰䷛䷪䷖䷚䷃䷨䷳䷕䷑䷙䷢䷔䷿䷥䷷䷝䷱䷍䷓䷩䷺䷼䷴䷤䷸䷈䷋䷘䷅䷉䷠䷌䷫䷀☯";
