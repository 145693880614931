import { AppAvatar, AppChip, AppImg } from "atomic"
import React from "react";
export const Logos: React.FC = () => {
    return <>
        <AppChip>
            Powered by Chainlink
            <AppAvatar>
                <AppImg src="https://cryptologos.cc/logos/chainlink-link-logo.svg?v=014" alt="Chainlink" />
            </AppAvatar>
        </AppChip>
    </>
}