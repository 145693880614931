import create from "zustand";
type ActiveChainHook = {
    chainId: number
    setChainId: (chainId: number) => void
}
export const useActiveChain = create<ActiveChainHook>((set, store) => ({
    chainId: 137,//Default to polygon
    setChainId: (chainId) => {
        set({ chainId })
    }
}));