import { AppCard, AppConfig, AppGrid, AppItem, AppLabel, AppList } from "atomic";
import React from "react";
import { cache } from "../cache";
import { AboutWidget } from "../components/AboutWidget";
import { Logos } from "../components/Logos";
import { WalletConnector } from "../components/WalletConnector";
import { routes } from "../routes";

const Config: AppConfig =
{
    appId: "emergence.oracle-of-changes",
    version: "∞",
    title: "Oracle of Changes",
    cache,
    about: {
        component: AboutWidget
    },
    darkMode: true,
    routes,
    settings: { disabled: true },
    bottomBar: { end: Logos, start: WalletConnector },
    animated: true,
    serialization: {
        mode: "local"
    }

}
export default Config;