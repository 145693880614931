import { AppBadge, AppButton, AppCard, AppCol, AppColor, AppContent, AppGrid, AppItem, AppLabel, AppTitle } from "atomic";
import { ethers } from "ethers";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useWallet } from "use-wallet";
import { WalletConnector } from "../components/WalletConnector";
import { EspersOracle__factory } from "../contracts";
import { ORACLE_OF_CHANGES_CONTRACT_ADDRESS } from "../contracts/addresses";
import { useActiveChain } from "../hooks/useActiveChainHook";
import judgements from "../judgement/changes.json";
import { consultRoute } from "../routes/consult-route";


const trigram_names: Record<string, string> = {
  "☰": "Heaven",
  "☱": "Lake",
  "☲": "Fire",
  "☳": "Thunder",
  "☴": "Wind",
  "☵": "Water",
  "☶": "Mountain",
  "☷": "Earth"
}
const trigram_colors: Record<string, AppColor> = {
  "☰": "dark",
  "☱": "secondary",
  "☲": "danger",
  "☳": "warning",
  "☴": "tertiary",
  "☵": "primary",
  "☶": "warning",
  "☷": "favorite"
}
const binaryHegramsToTraditionalOrder = [63, 0, 17, 34, 23, 58, 2, 16, 55, 59, 7, 56, 61, 47, 4, 8, 25, 38, 3, 48, 41, 37, 32, 1, 57, 39, 33, 30, 18, 45, 28, 14, 60, 15, 40, 5, 53, 43, 20, 10, 35, 49, 31, 62, 24, 6, 26, 22, 29, 46, 9, 36, 52, 11, 13, 44, 54, 27, 50, 19, 51, 12, 21, 42]
const place = ["Above", "Below"];
const findJudgement = (hexagramIndex: number) => judgements[hexagramIndex]
binaryHegramsToTraditionalOrder.map(x => x)


const Home: React.FC = () => {
  const { isConnected, ethereum, chainId, account } = useWallet()
  const { push } = useHistory();
  const activeChainId = useActiveChain(x => x.chainId)
  useEffect(() => {
    if (isConnected() && chainId === activeChainId) {
      const signer = (new ethers.providers.Web3Provider(ethereum)).getSigner();
      const oracleOfChangesAddress = ORACLE_OF_CHANGES_CONTRACT_ADDRESS[String(chainId)];
      const ORACLEOFCHANGES = EspersOracle__factory.connect(oracleOfChangesAddress, signer)
      account && ORACLEOFCHANGES.callStatic.lastReading().then((response) => {
        const hexReading = response.toHexString();
        const number = parseInt(hexReading, 16)
        if (number !== 8888888888888888 && number > 0) {
          push("/reading")
        }
      })
    }
  }, [account, activeChainId, chainId, ethereum, isConnected, push])
  return <AppContent center>
    <AppGrid>
      <AppCol />
      <AppCol>
        <AppCard title="Welcome to the Oracle of Changes" titleColor="tertiary">
          <img src="/assets/images/deep/changes.png" alt="bg" />
          <p>
            The Oracle of changes is an on chain Yi Jing oracle with provably random numbers provided by chainlink.
          </p>
          <AppItem>
            <AppLabel color="success" >Please Connect wallet to consult Oracle</AppLabel>
          </AppItem>
          {isConnected() ? <AppButton expand="full" color="favorite" routerLink={consultRoute.path}>
            <AppTitle >
              Consult Oracle
            </AppTitle>
          </AppButton> : <>
            <WalletConnector />
          </>}
        </AppCard>
      </AppCol>
      <AppCol />
    </AppGrid>
  </AppContent>
};

export default Home;
