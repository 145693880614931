import { parseEther } from "@ethersproject/units"
import { AppBadge, AppButton, AppChip, AppContent, AppItem, AppItemDivider, AppLabel, AppLoadingCard, AppModal, AppSequence, AppSpinner, AppText, AppTitle, useNotifications } from "atomic"
import AppRange from "atomic/dist/components/AppRange"
import { CompletionStatus } from "atomic/dist/hooks/useCompletion"
import { BigNumber, ethers } from "ethers"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useWallet } from "use-wallet"
import { EspersOracle__factory, LinkTokenInterface__factory } from "../contracts"
import { LINK_CONTRACT_ADDRESS, ORACLE_OF_CHANGES_CONTRACT_ADDRESS } from "../contracts/addresses"
import { useActiveChain } from "../hooks/useActiveChainHook"
import { useReading } from "../hooks/useReadingHook"
import { readingRoute } from "../routes/reading-route"
import { WalletConnector } from "./WalletConnector"



const ApproveLink: React.FC<{ chainId: number, ethereum: any, onStatusChange: (status: CompletionStatus) => void }> = ({ onStatusChange, ethereum, chainId }) => {
    const signer = (new ethers.providers.Web3Provider(ethereum)).getSigner();
    const LINK = LinkTokenInterface__factory.connect(LINK_CONTRACT_ADDRESS[String(chainId)], signer)
    const oracleOfChangesAddress = ORACLE_OF_CHANGES_CONTRACT_ADDRESS[String(chainId)];
    const ORACLEOFCHANGES = EspersOracle__factory.connect(oracleOfChangesAddress, signer)
    const { account } = useWallet()
    const [allowance, setAllowance] = useState<string>("");
    const [fee, setFee] = useState<string>("");
    const [feeVisual, setFeeVisual] = useState<string>("");
    const [balance, setBalance] = useState<string>("");
    const [balanceVisual, setBalanceVisual] = useState<string>("");
    const isApproved = account && parseInt(allowance, 16) >= parseInt(fee, 16) && fee !== "" && parseInt(balance, 16) >= parseInt(fee, 16)
    const [status, setStatus] = useState<"approve" | "approving" | "approved">("approve")
    useEffect(() => {
        account && ORACLEOFCHANGES.callStatic.fee().then((response) => {
            const feeAmountReadable = parseInt(response.toBigInt().toString()) / 1000000000000000000
            setFeeVisual("" + feeAmountReadable)
            setFee(response.toHexString());
        });

        // Please listen to events here, 
        const interval = setInterval(() => {
            account && LINK.callStatic.balanceOf(account).then((response) => {
                const balanceReadable = parseFloat(response.toBigInt().toString()) / 1000000000000000000
                setBalanceVisual("" + balanceReadable);
                setBalance(response.toHexString());
            });
            account && LINK.callStatic.allowance(account, oracleOfChangesAddress).then((response) => {
                setAllowance(response.toHexString());
                if (parseInt(allowance, 16) >= parseInt(fee, 16) && fee !== "" && parseInt(balance, 16) >= parseInt(fee, 16)) {
                    setStatus("approved")
                    onStatusChange("valid");
                    clearInterval(interval);
                }
            }).catch((e) => {
                setStatus("approve")
                onStatusChange("locked")
            })
        }, 1000)

    }, [LINK.callStatic, ORACLEOFCHANGES.callStatic, account, allowance, balance, fee, onStatusChange, oracleOfChangesAddress])
    // useEffect(() => {
    //     isApproved && parseInt(allowance, 16) >= parseInt(fee, 16) && fee !== "" && onStatusChange("valid");
    // }, [onStatusChange, allowance, fee, isApproved])

    return <>
        <AppItem>
            <AppBadge color={"light"}>YOUR LINK :{balanceVisual}</AppBadge>
            <AppBadge color={"tertiary"}>LINK FEE:{feeVisual}</AppBadge>

        </AppItem>
        {chainId === 56 && !isApproved && <AppItem href="https://pancakeswap.finance/swap?outputCurrency=0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd">Get Chainlink from Pancake Swap</AppItem>}
        {chainId === 56 && !isApproved && <AppItem href="https://pegswap.chain.link">Convert Pegged Chainlink to ERC 677</AppItem>}

        {chainId === 137 && !isApproved && <AppItem href="https://quickswap.exchange/#/swap?outputCurrency=0x514910771AF9Ca656af840dff83E8264EcF986CA">Get Chainlink from Quick Swap</AppItem>}
        {chainId === 137 && !isApproved && <AppItem href="https://pegswap.chain.link/">Convert Pegged Chainlink to ERC 677</AppItem>}

        <AppItem></AppItem>
        <AppButton disabled={status !== "approve"} color="light" expand="full" onClick={() => {
            LINK.functions.approve(oracleOfChangesAddress, fee).then(() => {
                setStatus("approving");
            })
        }}>
            <AppTitle color="favorite">
                {status} {status === "approving" && <AppSpinner />}
            </AppTitle>
        </AppButton>
    </>
}
const RequestReading: React.FC<{ chainId: number, ethereum: any, onStatusChange: (status: CompletionStatus) => void }> = ({ onStatusChange, ethereum, chainId }) => {
    const signer = (new ethers.providers.Web3Provider(ethereum)).getSigner();
    const oracleOfChangesAddress = ORACLE_OF_CHANGES_CONTRACT_ADDRESS[String(chainId)];
    const [ORACLEOFCHANGES] = useState(EspersOracle__factory.connect(oracleOfChangesAddress, signer));
    const { account, } = useWallet()
    const { setReading } = useReading()
    const [checkedStatus, setCheckedStatus] = useState<boolean>(false);
    const [rawTip, setTipRaw] = useState<number>(0);
    const nonZeroTip = 0 < rawTip;
    const [status, setStatus] = useState<"idle" | "in-progress" | "requested" | "ready" | "error">("idle")
    const { post } = useNotifications();
    useEffect(() => {
        if (checkedStatus) {
            return;
        } else {
            const interval = setInterval(() => {
                account && ORACLEOFCHANGES.callStatic.lastReading().then((res) => {
                    if (res) {
                        const hexReading = res.toHexString();
                        const number = parseInt(hexReading, 16)
                        if (number !== 8888888888888888 && number > 0) {
                            setStatus("ready");
                            setReading(number);
                            clearInterval(interval);
                        } else if (number === 8888888888888888) {
                            setStatus("in-progress");
                        }
                    }
                }).catch((e) => {
                    console.log(e);
                    onStatusChange("locked")
                })
            }, 1000)
            setCheckedStatus(true);
        }
    }, [ORACLEOFCHANGES, ORACLEOFCHANGES.callStatic, account, checkedStatus, onStatusChange, rawTip, setReading])
    return <>
        {status === "ready" && <>
            <AppButton expand="full" color="light" routerLink={readingRoute.path}> Reading is Ready</AppButton>
        </>}

        {status === "idle" && <>
            <AppLabel>Set Tip ({rawTip / 1000000000000000000}) </AppLabel><AppBadge>{ }</AppBadge>
            <AppRange onValueChange={(v) => {
                setTipRaw(v)
                return {}
            }} min={1} max={1000000000000000000} value={rawTip} >
            </AppRange>
            <AppButton disabled={!nonZeroTip} color="light" expand="full" onClick={() => {
                try {
                    const value = BigNumber.from(String(rawTip || 0)).toHexString();
                    ORACLEOFCHANGES.functions.requestReading({ value }).then((res) => {
                        setStatus("requested");
                    }).catch((e) => {
                        console.log(e, "error");
                        onStatusChange("locked")
                    })
                } catch (e: any) {
                    post({ id: "requrest-error", message: e.toString(), color: "warning", path: "" })
                    console.log(e);
                }
            }}>
                <AppText color="favorite">
                    Request Reading
                </AppText>
            </AppButton>
        </>
        }
        {
            status === "requested" && <>
                <AppModal isOpen={true} onDismiss={function (): void {

                }}>
                    <AppLoadingCard title="Requesting Reading" />
                    <img alt="Diagram of Changes" src="https://smol.mypinata.cloud/ipfs/Qmdsn4J8tkwBDPR5Ye91je6FjYaRtojTncyKuATRkWtreV" />
                </AppModal>
                <AppChip>
                    Reading Requested<AppSpinner />
                </AppChip>
            </>
        }
        {
            status === "in-progress" && <>
                <AppModal  isOpen={true} onDismiss={function (): void {

                }}>
                    <AppLoadingCard title="In Progress" />
                    <img alt="Diagram of Changes" src="https://smol.mypinata.cloud/ipfs/Qmdsn4J8tkwBDPR5Ye91je6FjYaRtojTncyKuATRkWtreV" />
                    <AppLoadingCard title="In Progress" />
                </AppModal>
                <AppChip>
                    Reading In Progress <AppSpinner />
                </AppChip>
            </>
        }
    </>
}

export const PerformReadingSequence: React.FC = () => {
    const { isConnected, chainId, ethereum } = useWallet()
    const isConnectedToCorrectChain = useActiveChain(x => x.chainId) === chainId && isConnected()
    const { push } = useHistory()
    const feeAmount = "0.001";
    return <AppContent center>
        {isConnectedToCorrectChain ? <AppSequence sequence={{
            title: "Consult The Oracle of Changes", elements: [{
                "title": "Approve LINK fee to begin", guidance: "LINK provides high quality provably random numbers. fee is set to " + feeAmount + " LINK",
                component: ({ onStatusChange }) => ApproveLink({ chainId, ethereum, onStatusChange })
            }, {
                component: ({ onStatusChange }) => RequestReading({ chainId, ethereum, onStatusChange })
                , guidance: "", title: "Request Reading"

            }]
        }} onNext={() => {
            push("/reading")
        }} onBack={function (): void {
        }} /> : <>
            <WalletConnector />
        </>}</AppContent>
}